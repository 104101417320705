import { ref } from 'vue'
// import { sdk } from './jsSdk'

// 回到顶部
function backTop(): void {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

// 判断是否为APP
function isApp() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}

// 获取APP内的顶部状态栏高度
// 自定义hook
function useGetStatusBarHeight() {
  const h = ref(0)

  // sdk
  //   .getBarHeight()
  //   .then((r: any) => {
  //     h.value = r.barHeight
  //   })
  //   .catch(e => {
  //     console.info('getAPPstatusHeight:0 ===> [未在app环境内]')
  //   })

  return h
}

// 获取URL参数
function getQueryString(name: any) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.href.split('?')[1] ? window.location.href.split('?')[1].match(reg) : null
  if (r != null) return decodeURIComponent(r[2])
  return null
}

export { backTop, isApp, useGetStatusBarHeight, getQueryString }
